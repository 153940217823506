// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: auto;
}

.header a {
  margin: 30px 150px;
  cursor: pointer;
  text-decoration: none;
  font-weight: var(--roboto-bold);
  color: var(--secondary-color);
  font-size: 20px;
  min-width: 150px;
}

.header a:hover {
  color: var(--primary-text);
}

.main-logo {
  max-width: 300px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .header {
    flex-direction: column;
    align-items: stretch;
  }

  .main-logo {
    order: 1;
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
  }

  .header a {
    order: 2;
    margin: 10px auto;
    font-size: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/header/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,+BAA+B;EAC/B,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE;IACE,sBAAsB;IACtB,oBAAoB;EACtB;;EAEA;IACE,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,QAAQ;IACR,iBAAiB;IACjB,eAAe;EACjB;AACF","sourcesContent":[".header {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 20px;\r\n  margin: auto;\r\n}\r\n\r\n.header a {\r\n  margin: 30px 150px;\r\n  cursor: pointer;\r\n  text-decoration: none;\r\n  font-weight: var(--roboto-bold);\r\n  color: var(--secondary-color);\r\n  font-size: 20px;\r\n  min-width: 150px;\r\n}\r\n\r\n.header a:hover {\r\n  color: var(--primary-text);\r\n}\r\n\r\n.main-logo {\r\n  max-width: 300px;\r\n  cursor: pointer;\r\n}\r\n\r\n@media screen and (max-width: 1000px) {\r\n  .header {\r\n    flex-direction: column;\r\n    align-items: stretch;\r\n  }\r\n\r\n  .main-logo {\r\n    order: 1;\r\n    width: 100%;\r\n    margin: auto;\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  .header a {\r\n    order: 2;\r\n    margin: 10px auto;\r\n    font-size: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
