import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import trivialLogo from "../../assets/trivial-logo-main-color.svg";
import "./About.css";

function About() {
  useEffect(() => {
    const textHeaderElements = document.querySelectorAll(".about-container");
    textHeaderElements.forEach((element) => {
      setTimeout(() => {
        element.classList.add("visible");
      }, 100);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>About - Trivial</title>
        <meta property="og:site_name" content="Trivial" />
        <meta
          name="description"
          content="Learn about Trivial, a trivia game developed by Tiberiu Matei and powered by the Open Trivia Database (OpenTDB)."
        />
        <meta
          name="keywords"
          content="trivia game, quiz game, open trivia db, trivia questions, quiz questions, trivia app, online trivia, trivia game features, trivia game development, trivia game implementation"
        />
      </Helmet>
      <h1 className="categories-header">About</h1>
      <div className="about-container">
        <div className="about-text">
          <span>
            Welcome to{" "}
            <img
              className="main-logo"
              src={trivialLogo}
              alt="Trivial logo used in the Welcome message"
            />
          </span>
          <br />
          Trivial is developed and maintained by{" "}
          <a
            href="https://github.com/TiberiuMatei"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tiberiu Matei
          </a>
          . This trivia game is powered by the{" "}
          <a
            href="https://opentdb.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Trivia Database (OpenTDB)
          </a>
          , offering a wide variety of questions to challenge and entertain
          players.
        </div>
        <div className="about-text-header">How It Works</div>
        <div className="about-text">
          Playing Trivial is easy! Choose your favorite categories, select a
          difficulty level, select up to 50 questions, start playing, score, try
          maximizing the score and have some fun! Whether you prefer challenging
          trivia, thought-provoking prompts, or lighthearted questions, you have
          the flexibility to choose the questions that best suit your interests
          and gameplay style.
        </div>
        <div className="about-text-header">Features</div>
        <div className="about-text">
          Choose from a wide range of trivia categories, including history,
          computers, art, film and more. Select your preferred difficulty level
          to suit your trivia skills, whether you're a novice or a trivia
          master. You have the flexibility to customize the number of questions
          you want to engage with, with a maximum limit of 50 questions. You can
          also generate links with your selected setup in order to start the
          quiz. Enjoy a seamless gaming experience thanks to an intuitive
          interface and responsive design!
        </div>
        <div className="about-text-header">Credits</div>
        <div className="about-text">
          <ul>
            <li>
              <a
                href="https://opentdb.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Open Trivia Database (OpenTDB)
              </a>
              : for curating the database of trivia questions which is used to
              power this website
            </li>
            <li>
              <a
                href="https://fontawesome.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Font Awesome
              </a>
              : for providing a comprehensive library of icons that the website
              uses in order to enhance the visual experience
            </li>
            <li>
              <a
                href="https://www.youtube.com/@Hyperplexed"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hyperplexed
              </a>
              : whose YouTube content has served as a source of inspiration for
              this website
            </li>
          </ul>
        </div>

        <FontAwesomeIcon icon={faHeart} size="3x" beat />

        <div className="about-text">Thank you for playing on Trivial!</div>
      </div>
    </>
  );
}

export default About;
