import Loader from "../loader/Loader";
import {
  ITriviaQuestionCount,
  difficultyMapping,
} from "../selectedCategory/SelectedCategory";
import "./CategoriesQuestionsCount.css";

function CategoriesQuestionCount({
  categoryQuestionCount,
  loadingDisplay,
  difficulty,
}: {
  categoryQuestionCount: ITriviaQuestionCount;
  loadingDisplay: boolean;
  difficulty: string;
}) {
  return (
    <>
      {loadingDisplay ? (
        <Loader />
      ) : (
        <div className="trivia-count">
          <p className="trivia-count-main">
            Please note that you can select a maximum of{" "}
            <span>50 questions per quiz</span>!
          </p>

          {difficulty === difficultyMapping.ANY_DIFFICULTY && (
            <p>
              Total number of questions for this category:{" "}
              <span>{categoryQuestionCount?.total_question_count}</span>
            </p>
          )}

          {difficulty === difficultyMapping.EASY && (
            <p>
              Total number of <span>easy</span> questions:{" "}
              <span>{categoryQuestionCount?.total_easy_question_count}</span>
            </p>
          )}

          {difficulty === difficultyMapping.MEDIUM && (
            <p>
              Total number of <span>medium</span> questions:{" "}
              <span>{categoryQuestionCount?.total_medium_question_count}</span>
            </p>
          )}

          {difficulty === difficultyMapping.HARD && (
            <p>
              Total number of <span>hard</span> questions:{" "}
              <span>{categoryQuestionCount?.total_hard_question_count}</span>
            </p>
          )}
        </div>
      )}
    </>
  );
}

export default CategoriesQuestionCount;
