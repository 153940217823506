import {
  faArrowLeft,
  faArrowUp91,
  faCloudBolt,
  faCloudSunRain,
  faCopy,
  faPlay,
  faRainbow,
  faShareFromSquare,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import getQueryParam from "../../util/GetQueryParam";
import revertCategoryFormat from "../../util/RevertFormatCategories";
import { ITriviaCategory } from "../App";
import CategoriesQuestionCount from "../categoriesQuestionCount/CategoriesQuestionsCount";
import Loader from "../loader/Loader";
import Modal from "../modal/Modal";
import Separator from "../separator/Separator";
import ServerError from "../serverError/ServerError";
import TriviaQuestions from "../triviaQuestions/TriviaQuestions";
import "./SelectedCategory.css";

export interface ITriviaQuestionCount {
  total_easy_question_count?: number;
  total_hard_question_count?: number;
  total_medium_question_count?: number;
  total_question_count?: number;
}

export enum difficultyMapping {
  ANY_DIFFICULTY = "any",
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard",
}

interface IQuestion {
  type: string;
  difficulty: string;
  category: string;
  question: string;
  correct_answer: string;
  incorrect_answers: string[];
}

interface ITriviaResponse {
  response_code: number;
  results: IQuestion[];
}

export interface ICategory {
  categoryId: number;
  categoryName: string;
}

const API_COUNT_URL: string = "https://opentdb.com/api_count.php?category=";
const API_GET_QUESTIONS_URL: string = "https://opentdb.com/api.php?";

function SelectedCategory({
  sortedCategories,
}: {
  sortedCategories: ITriviaCategory[];
}) {
  const [categoryQuestionCount, setcategoryQuestionCount] =
    useState<ITriviaQuestionCount>();
  const [difficulty, setDifficulty] = useState<string>(
    difficultyMapping.ANY_DIFFICULTY
  );
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState<number | "">("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [triviaQuestions, setTriviaQuestions] = useState<IQuestion[]>([]);
  const [responseCode, setResponseCode] = useState<number | null>(null);
  const [modalHeader, setModalHeader] = useState<string>("");
  const [modalContent, setModalContent] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [isLinkGenerated, setIsLinkGenerated] = useState<boolean>(false);
  const [generatedLink, setGeneratedLink] = useState<string>("");
  const [isClickToCopyVisible, setIsClickToCopyVisible] =
    useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const hasBeat: boolean =
    !!numberOfQuestions &&
    typeof numberOfQuestions === "number" &&
    numberOfQuestions > 0;

  useEffect(() => {
    // Scroll to the top when a new route is rendered (fix for mobile as well)
    window.scrollTo(0, 0);
  }, []);

  // Function to get the id value by name if the user inputs the category name in URL
  function getIdByName(name: string): number {
    const category = sortedCategories.find(
      (category) => category.name === name
    );
    return category ? category.id : -1;
  }

  function base64DecodeUTF8(encodedString: string) {
    // Convert the Base64 string to a Uint8Array
    const decodedBytes = Uint8Array.from(atob(encodedString), (c) =>
      c.charCodeAt(0)
    );

    // Decode the Uint8Array to a UTF-8 string
    const decoder = new TextDecoder("utf-8");
    return decoder.decode(decodedBytes);
  }

  // Logic to get the category id and category name if the user click on category or the user inputs the category in URL
  const categoryInfo: ICategory = location.state
    ? {
        categoryId: Number(location.state.categoryId),
        categoryName: location.state.categoryName,
      }
    : {
        categoryId: getIdByName(
          revertCategoryFormat(location.pathname.split("/").pop())
        ),
        categoryName: revertCategoryFormat(location.pathname.split("/").pop()),
      };

  useEffect(() => {
    if (categoryInfo.categoryId === -1) {
      // Category not found, navigate to 404 page
      navigate("/404");
    } else {
      const fetchCategoriesCount = async () => {
        try {
          const response = await fetch(API_COUNT_URL + categoryInfo.categoryId);
          const data = await response.json();
          setcategoryQuestionCount(data.category_question_count);
          setShowLoading(false);
        } catch (error) {
          console.error("Error fetching trivia count:", error);
          setShowLoading(false);
          setError(true);
        }
      };

      fetchCategoriesCount();
    }
  }, []);

  function handleChange(e: React.FormEvent<HTMLInputElement>) {
    setNumberOfQuestions(Number(e.currentTarget.value));
  }

  const fetchQuestions = async () => {
    try {
      setFetchLoading(true);
      let response = null;
      if (difficulty !== difficultyMapping.ANY_DIFFICULTY) {
        response = await fetch(
          API_GET_QUESTIONS_URL +
            `amount=${numberOfQuestions}&category=${categoryInfo.categoryId}&difficulty=${difficulty}&encode=base64`
        );
      } else {
        response = await fetch(
          API_GET_QUESTIONS_URL +
            `amount=${numberOfQuestions}&category=${categoryInfo.categoryId}&encode=base64`
        );
      }
      if (!response.ok) {
        setFetchLoading(false);
        if (response.status === 429) {
          setResponseCode(5);
        }
        if (response.status === 500) {
          setError(true);
        }
        throw new Error("Failed to fetch data");
      }
      const data: ITriviaResponse = await response.json();
      // Decode questions and answers
      const decodedQuestions: IQuestion[] = data.results.map(
        (question: IQuestion) => {
          return {
            ...question,
            type: base64DecodeUTF8(question.type),
            difficulty: base64DecodeUTF8(question.difficulty),
            category: base64DecodeUTF8(question.category),
            question: base64DecodeUTF8(question.question),
            correct_answer: base64DecodeUTF8(question.correct_answer),
            incorrect_answers: question.incorrect_answers.map(
              (answer: string) => base64DecodeUTF8(answer)
            ),
          };
        }
      );

      setTriviaQuestions(decodedQuestions);
      setResponseCode(data.response_code);
      setFetchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    if (responseCode === 1) {
      setModalHeader("No Results");
      switch (difficulty) {
        case difficultyMapping.ANY_DIFFICULTY: {
          setModalContent(
            `There are not enough questions for your selection! Please check the total number of questions for the selected difficulty!`
          );
          break;
        }
        case difficultyMapping.EASY: {
          setModalContent(
            `There are not enough questions for your selection! Please check the total number of questions for the selected difficulty!`
          );
          break;
        }
        case difficultyMapping.MEDIUM: {
          setModalContent(
            `There are not enough questions for your selection! Please check the total number of questions for the selected difficulty!`
          );
          break;
        }
        case difficultyMapping.HARD: {
          setModalContent(
            `There are not enough questions for your selection! Please check the total number of questions for the selected difficulty!`
          );
          break;
        }
      }
      openModal();
      setResponseCode(null);
    } else if (responseCode === 2) {
      setModalHeader("Invalid Parameter");
      setModalContent(
        "Argument is not valid. Please enter a valid number of questions!"
      );
      openModal();
      setResponseCode(null);
    } else if (responseCode === 5) {
      setModalHeader("Rate Limit Exceeded");
      setModalContent(
        "Too many requests have occurred, please try again in 5 seconds!"
      );
      openModal();
      setResponseCode(null);
    }
  }, [responseCode]);

  const handleFetchDataClick = async () => {
    if (
      numberOfQuestions.toString().includes(".") ||
      numberOfQuestions.toString().includes("-") ||
      !/^\d+$/.test(numberOfQuestions.toString())
    ) {
      setModalHeader("Invalid Value");
      setModalContent(
        "Input is not valid. Please enter a valid number of questions!"
      );
      openModal();
      return;
    }
    if (Number(numberOfQuestions) > 50) {
      setModalHeader("Limit Exceeded");
      setModalContent("You can select a maximum of 50 questions per quiz!");
      openModal();
      setResponseCode(null);
      return;
    }
    await fetchQuestions();
  };

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  const handleBackToCategories = () => {
    navigate("/");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Enter" &&
      /^\d+$/.test(event.currentTarget.value) &&
      parseInt(event.currentTarget.value) > 0
    ) {
      handleFetchDataClick();
    }
  };

  // Add generate trivia functionality from query params
  useEffect(() => {
    if (!location.state && location.search) {
      const numberOfQuestionsFromURL: string | null = getQueryParam(
        location.search,
        "amount"
      );
      const difficultyFromURL: string | null = getQueryParam(
        location.search,
        "difficulty"
      );

      if (numberOfQuestionsFromURL && difficultyFromURL) {
        switch (difficultyFromURL) {
          case "easy": {
            setDifficulty(difficultyMapping.EASY);
            break;
          }
          case "medium": {
            setDifficulty(difficultyMapping.MEDIUM);
            break;
          }
          case "hard": {
            setDifficulty(difficultyMapping.HARD);
            break;
          }
          case "any": {
            setDifficulty(difficultyMapping.ANY_DIFFICULTY);
            break;
          }
        }
      }
      setNumberOfQuestions(Number(numberOfQuestionsFromURL));
    }
  }, [location.search]);

  // Trigger this action for query params only
  useEffect(() => {
    if (location.search !== "" && numberOfQuestions !== "") {
      handleFetchDataClick();
    }
  }, [numberOfQuestions]);

  function handleGenerateLinkClick() {
    setGeneratedLink(
      `${window.location.origin}${location.pathname}?difficulty=${difficulty}&amount=${numberOfQuestions}`
    );
    setIsLinkGenerated(true);
  }

  function handleClickToCopy() {
    navigator.clipboard.writeText(generatedLink);
    setIsClickToCopyVisible(true);
  }

  useEffect(() => {
    if (isClickToCopyVisible) {
      const timeout = setTimeout(() => {
        setIsClickToCopyVisible(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
  }, [isClickToCopyVisible]);

  if (error) {
    return <ServerError />;
  }

  return (
    <>
      <Helmet>
        <title>{categoryInfo.categoryName} Quiz Setup - Trivial</title>
        <meta property="og:site_name" content="Trivial" />
        <meta
          name="description"
          content={`Trivial quiz setup (difficulty and number of questions) for the category: ${categoryInfo.categoryName}.`}
        />
        <meta
          name="keywords"
          content="setup trivia game, configure quiz, trivia game settings, quiz categories, difficulty level, number of questions, trivia quiz setup, customize trivia game, prepare trivia game, trivia game configuration, online quiz setup"
        />
      </Helmet>
      {responseCode === 0 ? (
        <TriviaQuestions
          triviaQuestions={triviaQuestions}
          handleFetchDataClick={handleFetchDataClick}
          categoryInfo={categoryInfo}
          difficulty={difficulty}
          sortedCategories={sortedCategories}
        />
      ) : (
        <>
          <h1 className="categories-header">{categoryInfo.categoryName}</h1>
          <h2 className="difficulty-header">Please select quiz difficulty</h2>
          <div className="select-difficulty">
            <button
              className={`difficulty-btn ${
                difficulty === difficultyMapping.EASY &&
                "selected-difficulty-button"
              }`}
              onClick={() => setDifficulty(difficultyMapping.EASY)}
            >
              <div className="easy-icon">
                <FontAwesomeIcon
                  icon={faSun}
                  size="1x"
                  beat={difficulty === difficultyMapping.EASY}
                />
              </div>
              Easy
            </button>
            <button
              className={`difficulty-btn ${
                difficulty === difficultyMapping.MEDIUM &&
                "selected-difficulty-button"
              }`}
              onClick={() => setDifficulty(difficultyMapping.MEDIUM)}
            >
              <div className="medium-icon">
                <FontAwesomeIcon
                  icon={faCloudSunRain}
                  size="1x"
                  beat={difficulty === difficultyMapping.MEDIUM}
                />
              </div>
              Medium
            </button>
            <button
              className={`difficulty-btn ${
                difficulty === difficultyMapping.HARD &&
                "selected-difficulty-button"
              }`}
              onClick={() => setDifficulty(difficultyMapping.HARD)}
            >
              <div className="hard-icon">
                <FontAwesomeIcon
                  icon={faCloudBolt}
                  size="1x"
                  beat={difficulty === difficultyMapping.HARD}
                />
              </div>
              Hard
            </button>
            <button
              className={`difficulty-btn ${
                difficulty === difficultyMapping.ANY_DIFFICULTY &&
                "selected-difficulty-button"
              }`}
              onClick={() => setDifficulty(difficultyMapping.ANY_DIFFICULTY)}
            >
              <div className="hard-icon">
                <FontAwesomeIcon
                  icon={faRainbow}
                  size="1x"
                  beat={difficulty === difficultyMapping.ANY_DIFFICULTY}
                />
              </div>
              Any difficulty
            </button>
          </div>
          <Separator size="small" />

          <h2 className="difficulty-header">
            Please input the number of questions -{" "}
            <span className="difficulty-header-span">
              must be a whole number greater than zero
            </span>
          </h2>

          <CategoriesQuestionCount
            categoryQuestionCount={categoryQuestionCount!}
            loadingDisplay={showLoading}
            difficulty={difficulty}
          />

          <div className="input-container">
            <FontAwesomeIcon
              icon={faArrowUp91}
              size="2x"
              className="input-icon"
              bounce
            />
            <input
              id="number-input"
              className="questions-number-input"
              type="number"
              step="1"
              placeholder="Set the number of questions"
              required
              onChange={handleChange}
              onKeyDown={handleKeyPress}
            ></input>
          </div>

          <Separator size="small" />

          <div className="action-buttons-container">
            <button
              className="back-to-categories-button"
              onClick={handleBackToCategories}
              disabled={fetchLoading}
            >
              <div className="back-icon">
                <FontAwesomeIcon icon={faArrowLeft} size="1x" />
              </div>
              BACK
            </button>
            <button
              className="generate-button"
              onClick={handleGenerateLinkClick}
              disabled={
                fetchLoading || !numberOfQuestions || numberOfQuestions <= 0
              }
            >
              <div className="play-icon">
                <FontAwesomeIcon icon={faShareFromSquare} size="1x" />
              </div>
              GENERATE LINK
            </button>
            <button
              className="play-button"
              onClick={handleFetchDataClick}
              disabled={
                fetchLoading || !numberOfQuestions || numberOfQuestions <= 0
              }
            >
              <div className="play-icon">
                <FontAwesomeIcon icon={faPlay} size="1x" beat={hasBeat} />
              </div>
              PLAY
            </button>
          </div>

          {isLinkGenerated && (
            <>
              <div className="generated-link">
                <h2>
                  Copy and share the link below in order to start the quiz with
                  the selected setup!
                </h2>
                <div className="generated-link-content">
                  <p>{generatedLink}</p>
                  <div onClick={handleClickToCopy} className="copy-button">
                    <FontAwesomeIcon icon={faCopy} size="2x" />
                  </div>
                </div>
              </div>
              <div
                className={`text-container ${
                  isClickToCopyVisible ? "show" : "hide"
                }`}
              >
                <p>Copied!</p>
              </div>
            </>
          )}

          {fetchLoading && <Loader />}
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <h2 className="modal-header">{modalHeader}</h2>
            <p>{modalContent}</p>
          </Modal>
        </>
      )}
    </>
  );
}

export default SelectedCategory;
