function revertCategoryFormat(categoryPath: string | undefined): string {
  return categoryPath!
    .split("-")
    .map((word) => {
      // Capitalize the first letter of each word, except when "and" is part of the word
      return word
        .split("and")
        .map(
          (subWord) =>
            subWord.charAt(0).toUpperCase() + subWord.slice(1).toLowerCase()
        )
        .join("and");
    })
    .join(" ");
}

export default revertCategoryFormat;
