// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.separator {
  height: 2px;
  background: linear-gradient(
    to right,
    transparent,
    var(--secondary-text),
    transparent
  );
  margin: 10px 0;
}

.small-separator-container {
  display: flex;
  justify-content: center; /* Center the separator horizontally */
}

.small-separator {
  height: 2px;
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 10%,
    var(--secondary-text) 50%,
    transparent 90%,
    transparent 100%
  );
  width: 100%;
  max-width: 920px;
  margin-top: 10px;
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/separator/Separator.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX;;;;;GAKC;EACD,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB,EAAE,sCAAsC;AACjE;;AAEA;EACE,WAAW;EACX;;;;;;;GAOC;EACD,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".separator {\r\n  height: 2px;\r\n  background: linear-gradient(\r\n    to right,\r\n    transparent,\r\n    var(--secondary-text),\r\n    transparent\r\n  );\r\n  margin: 10px 0;\r\n}\r\n\r\n.small-separator-container {\r\n  display: flex;\r\n  justify-content: center; /* Center the separator horizontally */\r\n}\r\n\r\n.small-separator {\r\n  height: 2px;\r\n  background: linear-gradient(\r\n    to right,\r\n    transparent 0%,\r\n    transparent 10%,\r\n    var(--secondary-text) 50%,\r\n    transparent 90%,\r\n    transparent 100%\r\n  );\r\n  width: 100%;\r\n  max-width: 920px;\r\n  margin-top: 10px;\r\n  margin-bottom: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
