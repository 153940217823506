import { ITriviaCategory } from "../components/App";

function formatCategories(categoriesArray: ITriviaCategory[]) {
  categoriesArray.forEach((element, index) => {
    if (element.name.includes("Entertainment:")) {
      categoriesArray[index].name = element.name.replace("Entertainment: ", "");
    }
    if (element.name.includes("Science:")) {
      categoriesArray[index].name = element.name.replace("Science: ", "");
    }
    if (element.name.includes("&")) {
      categoriesArray[index].name = element.name.replace("&", "and");
    }
  });

  const sortedCategories: ITriviaCategory[] = categoriesArray.sort((a, b) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  );
  return sortedCategories;
}

export default formatCategories;
