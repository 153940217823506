import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faDAndD } from "@fortawesome/free-brands-svg-icons";
import {
  faAtom,
  faBook,
  faBrain,
  faCarRear,
  faChessRook,
  faComputer,
  faDragon,
  faEarthAmericas,
  faFilm,
  faGamepad,
  faHandshake,
  faImage,
  faLandmark,
  faLandmarkDome,
  faMask,
  faMasksTheater,
  faMusic,
  faOtter,
  faPalette,
  faSquareRootVariable,
  faStar,
  faTableTennis,
  faTabletScreenButton,
  faTv,
} from "@fortawesome/free-solid-svg-icons";

function getCategoryIcon(categoryId: number): IconProp {
  switch (categoryId) {
    case 27:
      return faOtter;
    case 25:
      return faPalette;
    case 16:
      return faChessRook;
    case 10:
      return faBook;
    case 32:
      return faDragon;
    case 26:
      return faStar;
    case 29:
      return faMask;
    case 18:
      return faComputer;
    case 11:
      return faFilm;
    case 30:
      return faTabletScreenButton;
    case 9:
      return faBrain;
    case 22:
      return faEarthAmericas;
    case 23:
      return faLandmarkDome;
    case 31:
      return faDAndD;
    case 19:
      return faSquareRootVariable;
    case 12:
      return faMusic;
    case 13:
      return faMasksTheater;
    case 20:
      return faLandmark;
    case 24:
      return faHandshake;
    case 17:
      return faAtom;
    case 21:
      return faTableTennis;
    case 14:
      return faTv;
    case 28:
      return faCarRear;
    case 15:
      return faGamepad;
  }
  return faImage;
}

export default getCategoryIcon;
