import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
  defaults,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import "./PieChartStatistics.css";

ChartJS.register(ArcElement, Tooltip, Legend);

function calculatePercentages(
  correctAnswers: number,
  totalNumberOfQuestions: number
): { correctPercentage: string; incorrectPercentage: string } {
  const correctPercentage = (
    (correctAnswers / totalNumberOfQuestions) *
    100
  ).toFixed(2);
  const incorrectPercentage = (
    ((totalNumberOfQuestions - correctAnswers) / totalNumberOfQuestions) *
    100
  ).toFixed(2);

  return {
    correctPercentage: correctPercentage,
    incorrectPercentage: incorrectPercentage,
  };
}

function PieChartStatistics({
  finalScore,
  numberOfQuestions,
}: {
  finalScore: number;
  numberOfQuestions: number;
}) {
  defaults.font.family = "Roboto";
  defaults.font.size = 15;

  const calculatedPercentages: {
    correctPercentage: string;
    incorrectPercentage: string;
  } = calculatePercentages(finalScore, numberOfQuestions);

  const options = {
    plugins: {
      legend: {
        onClick: () => {},
      },
    },
  };

  const data = {
    labels: ["Correct", "Incorrect"],
    datasets: [
      {
        label: "Percentage",
        data: [
          calculatedPercentages.correctPercentage,
          calculatedPercentages.incorrectPercentage,
        ],
        backgroundColor: ["#26ae61", "#c2392c"],
        borderColor: ["#26ae61", "#c2392c"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="pie-chart-statistics">
      <Pie data={data} options={options} />
    </div>
  );
}

export default PieChartStatistics;
