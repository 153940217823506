import {
  faAngleDown,
  faAngleUp,
  faFlagCheckered,
  faGears,
  faGrip,
  faRepeat,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import getFinalScoreIcon from "../../services/FinalScoringIcons";
import { ICategory } from "../selectedCategory/SelectedCategory";
import Separator from "../separator/Separator";
import LineChartStatistics from "../statistics/lineChart/LineChartStatistics";
import PieChartStatistics from "../statistics/pieChart/PieChartStatistics";
import { QuizBreakdown } from "../triviaQuestions/TriviaQuestions";
import "./TriviaCompletion.css";

function TriviaCompletion({
  categoryInfo,
  handlePlayAgain,
  handleBackToSetup,
  finalScore,
  numberOfQuestions,
  scoreProgress,
  difficulty,
  quizBreakdown,
  formattedTimer,
}: {
  categoryInfo: ICategory;
  handlePlayAgain: MouseEventHandler<HTMLButtonElement> | undefined;
  handleBackToSetup: MouseEventHandler<HTMLButtonElement> | undefined;
  finalScore: number;
  numberOfQuestions: number;
  scoreProgress: number[];
  difficulty: string;
  quizBreakdown: QuizBreakdown[];
  formattedTimer: string;
}) {
  const [isClickToCopyVisible, setIsClickToCopyVisible] =
    useState<boolean>(false);
  const [isBeating, setIsBeating] = useState(false);
  const [showQuizBreakdown, setShowQuizBreakdown] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const shareUrl = window.location.origin;
  const shareTitle = "Trivial";
  const textToShare = `🏁 I've completed a Trivial quiz and my final score is: ${finalScore} / ${numberOfQuestions} \n\nCategory: ${categoryInfo.categoryName}\nDifficulty: ${difficulty}\nNumber of questions: ${numberOfQuestions}\n`;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleBackToCategories = () => {
    navigate("/");
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(textToShare);
    setIsClickToCopyVisible(true);
  };

  useEffect(() => {
    // Scroll to the top when a new route is rendered (fix for mobile as well)
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsBeating(true);
    const timeout = setTimeout(() => {
      setIsBeating(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [finalScore, numberOfQuestions]);

  useEffect(() => {
    if (isClickToCopyVisible) {
      const timeout = setTimeout(() => {
        setIsClickToCopyVisible(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
  }, [isClickToCopyVisible]);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: shareTitle,
          text: textToShare,
          url: shareUrl,
        });
      } else {
        handleCopyToClipboard();
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Quiz Completed - Trivial</title>
        <meta property="og:site_name" content="Trivial" />
        <meta
          name="description"
          content="Trivial quiz game completed with the selected setup."
        />
        <meta
          name="keywords"
          content="trivia quiz completed, quiz results, trivia game completion, share quiz results, quiz completion page, test your knowledge, trivia game, quiz game, play quizzes, trivia statistics, quiz statistics"
        />
      </Helmet>
      <h1 className="categories-header">{categoryInfo.categoryName}</h1>
      <div className="quiz-completed">
        <div className="quiz-completed-icon">
          <FontAwesomeIcon
            className="fa-flip"
            icon={faFlagCheckered}
            size="2x"
          />
        </div>
        <div className="quiz-completed-text">Quiz completed!</div>
      </div>

      <Separator size="small" />

      <div className="final-score">
        <div className="final-score-icon">
          <FontAwesomeIcon
            icon={getFinalScoreIcon(finalScore / numberOfQuestions)}
            size="2x"
            beat={isBeating}
          />
        </div>

        <div className="final-score-text">
          Your final score is{" "}
          <span className="final-score-span">{finalScore}</span>/
          {numberOfQuestions}
        </div>
      </div>

      <Separator size="small" />

      <h2 className="categories-header">Statistics</h2>
      <div className="quiz-completion-time">
        Quiz completed in <span>{formattedTimer}</span>
      </div>
      <div className="statistics-container">
        <LineChartStatistics
          numberOfQuestions={numberOfQuestions}
          scoreProgress={scoreProgress}
        />
        <PieChartStatistics
          finalScore={finalScore}
          numberOfQuestions={numberOfQuestions}
        />
      </div>

      <div className="quiz-breakdown-container">
        <button onClick={() => setShowQuizBreakdown(!showQuizBreakdown)}>
          {showQuizBreakdown ? "Hide" : "Show"} quiz breakdown
          {showQuizBreakdown ? (
            <FontAwesomeIcon icon={faAngleUp} size="1x" />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} size="1x" />
          )}
        </button>
        {showQuizBreakdown && (
          <div className="quiz-breakdown-wrapper">
            {quizBreakdown.map((quizItem, index) => (
              <div key={index} className="quiz-breakdown-item">
                <p className="quiz-breakdown-question">
                  <strong>Question {index + 1}:</strong> {quizItem.question}
                </p>
                <p className="quiz-breakdown-correct-answer">
                  <strong>Correct answer:</strong> {quizItem.correctAnswer}
                </p>
                <p
                  className={`quiz-breakdown-your-answer ${
                    quizItem.correctAnswer === quizItem.userAnswer
                      ? "correct"
                      : "incorrect"
                  }`}
                >
                  <strong>Your answer:</strong> {quizItem.userAnswer}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      <Separator size="small" />

      <div className="action-buttons-final-score-container">
        <button className="play-again-btn" onClick={handlePlayAgain}>
          <FontAwesomeIcon icon={faRepeat} size="1x" />
          Play again
        </button>

        {location.state && !location.search && (
          <button className="back-to-setup-btn" onClick={handleBackToSetup}>
            <FontAwesomeIcon icon={faGears} size="1x" />
            Back to setup
          </button>
        )}

        <button
          className="back-to-categories-btn"
          onClick={handleBackToCategories}
        >
          <FontAwesomeIcon icon={faGrip} size="1x" />
          Back to categories
        </button>

        {isMobile ? (
          <button className="share-btn" onClick={handleShare}>
            <FontAwesomeIcon icon={faShareNodes} size="1x" />
            Share
          </button>
        ) : (
          <button className="share-btn" onClick={handleCopyToClipboard}>
            <FontAwesomeIcon icon={faShareNodes} size="1x" />
            Share
          </button>
        )}
      </div>
      <div
        className={`text-container ${isClickToCopyVisible ? "show" : "hide"}`}
      >
        <p>Copied!</p>
      </div>
    </>
  );
}

export default TriviaCompletion;
