// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pie-chart-statistics {
  width: 400px !important;
  height: 400px !important;
}

@media screen and (max-width: 1500px) {
  .pie-chart-statistics {
    width: 300px !important;
    height: 300px !important;
  }
}

@media screen and (max-width: 1000px) {
  .pie-chart-statistics {
    width: 300px !important;
    height: 300px !important;
  }
}

@media screen and (max-width: 670px) {
  .pie-chart-statistics {
    width: 200px !important;
    height: 200px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/statistics/pieChart/PieChartStatistics.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE;IACE,uBAAuB;IACvB,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,wBAAwB;EAC1B;AACF","sourcesContent":[".pie-chart-statistics {\r\n  width: 400px !important;\r\n  height: 400px !important;\r\n}\r\n\r\n@media screen and (max-width: 1500px) {\r\n  .pie-chart-statistics {\r\n    width: 300px !important;\r\n    height: 300px !important;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 1000px) {\r\n  .pie-chart-statistics {\r\n    width: 300px !important;\r\n    height: 300px !important;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 670px) {\r\n  .pie-chart-statistics {\r\n    width: 200px !important;\r\n    height: 200px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
