// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-wrapper {
    min-height: calc(100vh - 125px);
    overflow-y: hidden;
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".main-wrapper {\r\n    min-height: calc(100vh - 125px);\r\n    overflow-y: hidden;\r\n    position: relative;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
