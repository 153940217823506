// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trivia-count {
  bottom: 0;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
  font-weight: var(--roboto-light);
  font-style: italic;
  color: var(--secondary-text);
  font-size: 1.2rem;
}

.trivia-count-main {
  margin: 20px;
}

.trivia-count span {
  color: var(--primary-color);
  font-weight: var(--roboto-regular);
}

@media screen and (max-width: 1000px) {
  .trivia-count {
    font-size: 0.9rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/categoriesQuestionCount/CategoriesQuestionsCount.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,gCAAgC;EAChC,kBAAkB;EAClB,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,kCAAkC;AACpC;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".trivia-count {\r\n  bottom: 0;\r\n  width: 100%;\r\n  margin-top: 50px;\r\n  margin-bottom: 20px;\r\n  font-weight: var(--roboto-light);\r\n  font-style: italic;\r\n  color: var(--secondary-text);\r\n  font-size: 1.2rem;\r\n}\r\n\r\n.trivia-count-main {\r\n  margin: 20px;\r\n}\r\n\r\n.trivia-count span {\r\n  color: var(--primary-color);\r\n  font-weight: var(--roboto-regular);\r\n}\r\n\r\n@media screen and (max-width: 1000px) {\r\n  .trivia-count {\r\n    font-size: 0.9rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
