import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./Disclaimer.css";

function Disclaimer() {
  useEffect(() => {
    const textHeaderElements = document.querySelectorAll(
      ".disclaimer-container"
    );
    textHeaderElements.forEach((element) => {
      setTimeout(() => {
        element.classList.add("visible");
      }, 100);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Disclaimer - Trivial</title>
        <meta property="og:site_name" content="Trivial" />
        <meta
          name="description"
          content="Learn about the disclaimer of Trivial, a trivia game that utilizes the Open Trivia Database (OpenTDB) API and Font Awesome Free icons. Users should exercise caution and discretion when interacting with the trivia content on this website."
        />
        <meta
          name="keywords"
          content="trivia game disclaimer, legal disclaimer, open trivia db disclaimer, data accuracy, limitations, legal information"
        />
      </Helmet>
      <h1 className="categories-header">Disclaimer</h1>
      <div className="disclaimer-container">
        <FontAwesomeIcon
          className="disclaimer-info-icon"
          icon={faCircleInfo}
          size="2x"
          bounce
        />
        <div className="disclaimer-text">
          This website utilizes the{" "}
          <a
            href="https://opentdb.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Trivia Database (OpenTDB)
          </a>{" "}
          API in order to provide trivia questions and answers, as well as{" "}
          <a
            href="https://fontawesome.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Font Awesome
          </a>{" "}
          Free icons for visual elements (except for the Trivial logo). While I
          strive to maintain accuracy and quality, I cannot guarantee the
          correctness or completeness of the content provided through these
          services.
        </div>
        <div className="disclaimer-text">
          Users should be aware that the trivia questions and answers displayed
          on this website are sourced from a third-party database and may not
          always reflect current information or be error-free. I do not endorse
          or verify the accuracy of the questions or answers provided by{" "}
          <a
            href="https://opentdb.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            OpenTDB
          </a>
          .
        </div>
        <div className="disclaimer-text">
          Furthermore, users should exercise caution and discretion when
          interacting with the trivia content on this website. I am not
          responsible for any consequences arising from the use or reliance on
          the information provided herein.
        </div>
        <div className="disclaimer-text">
          Additionally,{" "}
          <a
            href="https://opentdb.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Trivia Database (OpenTDB)
          </a>{" "}
          is licensed under{" "}
          <a
            href="https://creativecommons.org/licenses/by-sa/4.0/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CC BY-SA 4.0 DEED
          </a>
          . The use of{" "}
          <a
            href="https://fontawesome.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Font Awesome
          </a>{" "}
          Free icons on this website is subject to the{" "}
          <a
            href="https://fontawesome.com/license/free"
            target="_blank"
            rel="noopener noreferrer"
          >
            Font Awesome Free License
          </a>
          .
        </div>
        <div className="disclaimer-text">
          By accessing and using this website, you agree to waive any claims or
          liabilities against me regarding the accuracy, reliability, or
          suitability of the trivia content and{" "}
          <a
            href="https://fontawesome.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Font Awesome
          </a>{" "}
          icons. I reserve the right to modify, update, or remove any content of
          this website without prior notice.
        </div>
      </div>
    </>
  );
}

export default Disclaimer;
