// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-chart-statistics {
  width: 1000px !important;
  height: 500px !important;
}

@media screen and (max-width: 1500px) {
  .line-chart-statistics {
    width: 800px !important;
    height: 400px !important;
  }
}

@media screen and (max-width: 1170px) {
  .line-chart-statistics {
    width: 600px !important;
    height: 300px !important;
  }
}

@media screen and (max-width: 670px) {
  .line-chart-statistics {
    width: 350px !important;
    height: 175px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/statistics/lineChart/LineChartStatistics.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,wBAAwB;AAC1B;;AAEA;EACE;IACE,uBAAuB;IACvB,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,wBAAwB;EAC1B;AACF","sourcesContent":[".line-chart-statistics {\r\n  width: 1000px !important;\r\n  height: 500px !important;\r\n}\r\n\r\n@media screen and (max-width: 1500px) {\r\n  .line-chart-statistics {\r\n    width: 800px !important;\r\n    height: 400px !important;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 1170px) {\r\n  .line-chart-statistics {\r\n    width: 600px !important;\r\n    height: 300px !important;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 670px) {\r\n  .line-chart-statistics {\r\n    width: 350px !important;\r\n    height: 175px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
