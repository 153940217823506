// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: auto;
  font-weight: var(--roboto-light);
  font-style: italic;
}

.opentdb-container {
  display: flex;
  align-items: center;
}

.opentdb-logo {
  max-width: 125px;
  margin-left: 10px;
}

@media screen and (max-width: 670px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    gap: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;EACX;AACF","sourcesContent":[".footer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 20px;\r\n  margin: auto;\r\n  font-weight: var(--roboto-light);\r\n  font-style: italic;\r\n}\r\n\r\n.opentdb-container {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.opentdb-logo {\r\n  max-width: 125px;\r\n  margin-left: 10px;\r\n}\r\n\r\n@media screen and (max-width: 670px) {\r\n  .footer {\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    gap: 40px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
