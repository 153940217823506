import { faGhost, faGrip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "./PageNotFound.css";

function PageNotFound() {
  const navigate = useNavigate();
  const handleBackToCategories = () => {
    navigate("/");
  };
  return (
    <>
      <Helmet>
        <title>404 Trivia Not Found - Trivial</title>
        <meta property="og:site_name" content="Trivial" />
        <meta
          name="description"
          content="Sorry, the trivia page you are looking for does not exist on Trivial. It may be a broken or an outdated link or there could be a typo. Navigate back to the categories to explore more trivia."
        />
        <meta
          name="keywords"
          content="page not found, 404 error, URL not found, website error, site not found, navigate back, website navigation, website error handling"
        />
      </Helmet>
      <div className="not-found-container">
        <FontAwesomeIcon
          className="not-found-icon"
          icon={faGhost}
          size="6x"
          beat
        />
        <h1 className="not-found-header">404 Trivia Not Found</h1>
        <p>Sorry, the trivia page you are looking for does not exist.</p>
        <p>It may be a broken or an outdated link or there could be a typo.</p>
        <button
          className="back-to-categories-btn"
          onClick={handleBackToCategories}
        >
          <FontAwesomeIcon icon={faGrip} size="1x" />
          Back to categories
        </button>
      </div>
    </>
  );
}

export default PageNotFound;
