// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.server-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px;
}

.server-error-icon {
  color: var(--red-color);
}

.server-error-header {
  margin: 50px;
  color: var(--secondary-color);
  font-size: 70px;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.server-error-container p {
  font-size: 20px;
  font-weight: var(--roboto-medium);
  margin: 10px;
}

.status-check a {
  text-decoration: none;
  color: var(--primary-color);
  line-height: 140%;
}

.status-check a:hover {
  color: var(--secondary-color);
}

@media screen and (max-width: 670px) {
  .server-error-header {
    font-size: 40px;
  }

  .server-error-container p {
    font-size: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/serverError/ServerError.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,wCAAwC;AAC1C;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,eAAe;EACf,iCAAiC;EACjC,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".server-error-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    margin: 30px;\r\n}\r\n\r\n.server-error-icon {\r\n  color: var(--red-color);\r\n}\r\n\r\n.server-error-header {\r\n  margin: 50px;\r\n  color: var(--secondary-color);\r\n  font-size: 70px;\r\n  animation: float 2s ease-in-out infinite;\r\n}\r\n\r\n@keyframes float {\r\n  0% {\r\n    transform: translateY(0);\r\n  }\r\n  50% {\r\n    transform: translateY(-10px);\r\n  }\r\n  100% {\r\n    transform: translateY(0);\r\n  }\r\n}\r\n\r\n.server-error-container p {\r\n  font-size: 20px;\r\n  font-weight: var(--roboto-medium);\r\n  margin: 10px;\r\n}\r\n\r\n.status-check a {\r\n  text-decoration: none;\r\n  color: var(--primary-color);\r\n  line-height: 140%;\r\n}\r\n\r\n.status-check a:hover {\r\n  color: var(--secondary-color);\r\n}\r\n\r\n@media screen and (max-width: 670px) {\r\n  .server-error-header {\r\n    font-size: 40px;\r\n  }\r\n\r\n  .server-error-container p {\r\n    font-size: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
