import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import formatCategories from "../util/FormatCategories";
import "./App.css";
import About from "./about/About";
import Categories from "./categories/Categories";
import Disclaimer from "./disclaimer/Disclaimer";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Loader from "./loader/Loader";
import PageNotFound from "./pageNotFound/PageNotFound";
import SelectedCategory from "./selectedCategory/SelectedCategory";
import ServerError from "./serverError/ServerError";

export interface ITriviaCategory {
  id: number;
  name: string;
}

const API_CATEGORY_URL: string = "https://opentdb.com/api_category.php";

function App() {
  const [categories, setCategories] = useState<ITriviaCategory[]>([]);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(API_CATEGORY_URL);
        const data = await response.json();
        setCategories(data.trivia_categories);
      } catch (error) {
        console.error("Error fetching trivia categories:", error);
        setError(true);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    // Scroll to the top when a new route is rendered (fix for mobile as well)
    window.scrollTo(0, 0);
  }, []);

  const sortedCategories: ITriviaCategory[] = formatCategories(categories);

  if (sortedCategories.length === 0 && error === false) {
    return <Loader />;
  }

  if (error) {
    return <ServerError />;
  }

  return (
    <>
      <div className="main-wrapper">
        <Router>
          <Header />
          <Routes>
            <Route
              path="/"
              element={<Categories sortedCategories={sortedCategories} />}
            />
            <Route
              path="/:category"
              element={<SelectedCategory sortedCategories={sortedCategories} />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/:category/*" element={<PageNotFound />} />
            <Route path="/404" element={<PageNotFound />} />
          </Routes>
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;
