import openTriviaDBLogo from "../../assets/opentdb-logo.png";
import Separator from "../separator/Separator";
import "./Footer.css";

function Footer() {
  return (
    <>
      <Separator size="large" />
      <footer className="footer">
        <a
          href="https://ko-fi.com/C0C8WSORK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            height="36"
            style={{ border: "0px", height: "45px" }}
            src="https://storage.ko-fi.com/cdn/kofi1.png?v=3"
            alt="Buy Me a Coffee at ko-fi.com"
          />
        </a>
        <div className="opentdb-container">
          <div>Powered by</div>
          <a
            href="https://opentdb.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="opentdb-logo"
              src={openTriviaDBLogo}
              alt="OpenTriviaDB Logo"
            />
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;
