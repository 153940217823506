import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import getCategoryIcon from "../../services/CategoriesIcons";
import { ITriviaCategory } from "../App";
import Loader from "../loader/Loader";
import "./Categories.css";

function Categories({
  sortedCategories,
}: {
  sortedCategories: ITriviaCategory[];
}) {
  const [hoveredCardId, setHoveredCardId] = useState<number | null>(null);
  const baseURL = window.location.origin;

  useEffect(() => {
    // Scroll to the top when a new route is rendered (fix for mobile as well)
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const cardsElement = document.getElementById(
      "category-cards"
    ) as HTMLFormElement | null;
    if (cardsElement) {
      cardsElement.onmousemove = (e) => {
        for (const card of Array.from(
          document.getElementsByClassName(
            "category-card"
          ) as HTMLCollectionOf<HTMLElement>
        )) {
          const rect = card.getBoundingClientRect(),
            x = e.clientX - rect.left,
            y = e.clientY - rect.top;

          card.style.setProperty("--mouse-x", `${x}px`);
          card.style.setProperty("--mouse-y", `${y}px`);
        }
      };
    }
  });

  const handleMouseEnter = (categoryId: number) => {
    setHoveredCardId(categoryId);
  };

  const handleMouseLeave = () => {
    setHoveredCardId(null);
  };

  return (
    <>
      <Helmet>
        <title>
          Play trivia challenges with adjustable difficulty levels and a
          customizable number of questions - Trivial
        </title>
        <meta name="description" content="Select a category from Trivial." />
        <meta property="og:site_name" content="Trivial" />
        <meta
          name="keywords"
          content="trivia game, trivial game, quiz game, open trivia db, trivia questions, quiz questions, fun trivia, online trivia, trivia challenges, test your knowledge, trivia categories, play trivia online, free trivia game, trivia app, interactive trivia"
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "ItemList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "item": {
                  "@id": "${baseURL}",
                  "name": "Homepage"
                }
              },
              ${sortedCategories
                .map(
                  (category, index) => `{
                "@type": "ListItem",
                "position": ${index + 2},
                "item": {
                  "@id": "${baseURL}/${category.name
                    .toLowerCase()
                    .replaceAll(" ", "-")}",
                  "name": "${category.name}"
                }
              }`
                )
                .join(",")}
            ]
          }`}
        </script>
      </Helmet>
      <h1 className="categories-header">Categories</h1>
      <h2 className="categories-info-text">
        Please select a category from below
      </h2>
      {sortedCategories.length === 0 ? (
        <Loader />
      ) : (
        <div id="category-cards">
          {sortedCategories.map((category: ITriviaCategory) => (
            <Link
              key={category.id}
              className="category-card"
              to={`/${category.name.toLowerCase().replaceAll(" ", "-")}`}
              state={{ categoryId: category.id, categoryName: category.name }}
              onMouseEnter={() => handleMouseEnter(category.id)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="category-card-content">
                <div className="category-card-image">
                  <FontAwesomeIcon
                    icon={getCategoryIcon(category.id)}
                    size="3x"
                    bounce={hoveredCardId === category.id}
                  />
                </div>
                <div className="category-card-info-wrapper">
                  <div className="category-card-info">
                    <div className="category-card-info-title">
                      <h3>{category.name}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </>
  );
}

export default Categories;
