import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faFaceFrown,
    faFaceMeh,
    faFaceMehBlank,
    faFaceSmile,
    faSadTear,
    faTrophy
} from "@fortawesome/free-solid-svg-icons";

function getFinalScoreIcon(finalScoreScaled: number): IconProp {
  if (finalScoreScaled === 0) {
    return faSadTear;
  } else if (finalScoreScaled > 0 && finalScoreScaled < 0.5) {
    return faFaceFrown;
  } else if (finalScoreScaled === 0.5) {
    return faFaceMeh;
  } else if (finalScoreScaled > 0.5 && finalScoreScaled < 1) {
    return faFaceSmile;
  } else if (finalScoreScaled === 1) {
    return faTrophy;
  }
  else {
    return faFaceMehBlank;
  }
}

export default getFinalScoreIcon;
