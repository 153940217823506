import "./Separator.css";

function Separator({ size }: { size: "small" | "large" }) {
  return (
    <>
      {size === "large" ? (
        <div className="separator"></div>
      ) : (
        <div className="small-separator-container">
          <div className="small-separator"></div>
        </div>
      )}
    </>
  );
}

export default Separator;
