// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px;
}

.not-found-icon {
  margin-top: 50px;
  color: var(--primary-color);
}

.not-found-header {
  margin: 50px;
  color: var(--secondary-color);
  font-size: 70px;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.not-found-container p {
  font-size: 20px;
  font-weight: var(--roboto-medium);
  margin: 10px;
}

.not-found-container .back-to-categories-btn {
  margin: 50px;
}

@media screen and (max-width: 670px) {
  .not-found-header {
    font-size: 40px;
  }

  .not-found-container p {
    font-size: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pageNotFound/PageNotFound.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,wCAAwC;AAC1C;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,eAAe;EACf,iCAAiC;EACjC,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".not-found-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  margin: 30px;\r\n}\r\n\r\n.not-found-icon {\r\n  margin-top: 50px;\r\n  color: var(--primary-color);\r\n}\r\n\r\n.not-found-header {\r\n  margin: 50px;\r\n  color: var(--secondary-color);\r\n  font-size: 70px;\r\n  animation: float 2s ease-in-out infinite;\r\n}\r\n\r\n@keyframes float {\r\n  0% {\r\n    transform: translateY(0);\r\n  }\r\n  50% {\r\n    transform: translateY(-10px);\r\n  }\r\n  100% {\r\n    transform: translateY(0);\r\n  }\r\n}\r\n\r\n.not-found-container p {\r\n  font-size: 20px;\r\n  font-weight: var(--roboto-medium);\r\n  margin: 10px;\r\n}\r\n\r\n.not-found-container .back-to-categories-btn {\r\n  margin: 50px;\r\n}\r\n\r\n@media screen and (max-width: 670px) {\r\n  .not-found-header {\r\n    font-size: 40px;\r\n  }\r\n\r\n  .not-found-container p {\r\n    font-size: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
