import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  defaults,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "./LineChartStatistics.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineChartStatistics({
  numberOfQuestions,
  scoreProgress,
}: {
  numberOfQuestions: number;
  scoreProgress: number[];
}) {
  defaults.font.family = "Roboto";
  defaults.font.size = 15;

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "top" as const,
        onClick: () => {},
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Quiz questions",
        },
        grid: {
          color: "rgba(255, 255, 255, 0.05)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Quiz score",
        },
        grid: {
          color: "rgba(255, 255, 255, 0.05)",
        },
        max: numberOfQuestions,
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
  };

  const numerOfQuestionsArray: number[] = Array.from(
    Array(numberOfQuestions + 1).keys()
  );

  let labels = numerOfQuestionsArray.map((i, index) =>
    index === 0 ? "Quiz start" : "Question " + i
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Quiz score",
        data: scoreProgress,
        borderColor: "#41aaa5",
        backgroundColor: "#69c9ca",
      },
    ],
  };

  return (
    <div className="line-chart-statistics">
      <Line options={options} data={data} />
    </div>
  );
}

export default LineChartStatistics;
