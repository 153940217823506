import { faFireBurner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import "./ServerError.css";

function ServerError() {
  return (
    <>
      <Helmet>
        <title>500 Internal Server Error - Trivial</title>
        <meta property="og:site_name" content="Trivial" />
        <meta
          name="description"
          content="Sorry, something went wrong on Trivial. Please be patient or try again later."
        />
        <meta
          name="keywords"
          content="server error, 500 error, internal server error, technical difficulties, website error, website maintenance, website issues"
        />
      </Helmet>
      <div className="server-error-container">
        <FontAwesomeIcon
          className="server-error-icon"
          icon={faFireBurner}
          size="6x"
          shake
        />
        <h1 className="server-error-header">500 Internal Server Error</h1>
        <p>Sorry, something went wrong.</p>
        <p>Please be patient or try again later.</p>
        <p className="status-check">Additionally, you can check the <a
            href="https://stats.uptimerobot.com/r99niw4gk/50247"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Trivia Database (OpenTDB) status
          </a>.</p>
      </div>
    </>
  );
}

export default ServerError;
