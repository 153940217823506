// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  gap: 40px;
  margin: 40px;
  opacity: 0;
  transition: opacity 1.3s ease-in-out;
}

.visible {
  opacity: 1;
}

.disclaimer-info-icon {
  margin: 20px;
}

.disclaimer-text {
  font-weight: var(--roboto-regular);
  color: var(--primary-text);
  font-size: 1.2rem;
  max-width: 700px;
}

.disclaimer-text a {
  text-decoration: none;
  color: var(--primary-color);
  line-height: 140%;
}

.disclaimer-text a:hover {
  color: var(--secondary-color);
}

@media screen and (max-width: 800px) {
  .disclaimer-text {
    font-size: 1rem;
    max-width: 500px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/disclaimer/Disclaimer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,YAAY;EACZ,UAAU;EACV,oCAAoC;AACtC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kCAAkC;EAClC,0BAA0B;EAC1B,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":[".disclaimer-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  text-align: justify;\r\n  gap: 40px;\r\n  margin: 40px;\r\n  opacity: 0;\r\n  transition: opacity 1.3s ease-in-out;\r\n}\r\n\r\n.visible {\r\n  opacity: 1;\r\n}\r\n\r\n.disclaimer-info-icon {\r\n  margin: 20px;\r\n}\r\n\r\n.disclaimer-text {\r\n  font-weight: var(--roboto-regular);\r\n  color: var(--primary-text);\r\n  font-size: 1.2rem;\r\n  max-width: 700px;\r\n}\r\n\r\n.disclaimer-text a {\r\n  text-decoration: none;\r\n  color: var(--primary-color);\r\n  line-height: 140%;\r\n}\r\n\r\n.disclaimer-text a:hover {\r\n  color: var(--secondary-color);\r\n}\r\n\r\n@media screen and (max-width: 800px) {\r\n  .disclaimer-text {\r\n    font-size: 1rem;\r\n    max-width: 500px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
