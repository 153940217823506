import {
  faAngleDown,
  faAngleUp,
  faClipboardCheck,
  faClipboardQuestion,
  faFlagCheckered,
  faForwardStep,
  faHourglassStart,
  faRobot,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import convertSpecialChars from "../../util/ConvertSpecialChars";
import formatTime from "../../util/FormatTime";
import { ITriviaCategory } from "../App";
import Loader from "../loader/Loader";
import SelectedCategory, {
  ICategory,
} from "../selectedCategory/SelectedCategory";
import Separator from "../separator/Separator";
import ServerError from "../serverError/ServerError";
import TriviaCompletion from "../triviaCompletion/TriviaCompletion";
import "./TriviaQuestions.css";

export interface TriviaQuestion {
  type: string;
  difficulty: string;
  category: string;
  question: string;
  correct_answer: string;
  incorrect_answers: string[];
}

export interface QuizBreakdown {
  question: string;
  correctAnswer: string;
  userAnswer: string;
}

function TriviaQuestions({
  triviaQuestions,
  handleFetchDataClick,
  categoryInfo,
  difficulty,
  sortedCategories,
}: {
  triviaQuestions: TriviaQuestion[];
  handleFetchDataClick: () => Promise<void>;
  categoryInfo: ICategory;
  difficulty: string;
  sortedCategories: ITriviaCategory[];
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [score, setScore] = useState<number>(0);
  const [quizEnded, setQuizEnded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [backToSetup, setBackToSetup] = useState<boolean>(false);
  const [finalScore, setFinalScore] = useState<number>(0);
  const [scoreProgress, setScoreProgress] = useState<number[]>([0]);
  const [error, setError] = useState<boolean>(false);
  const [previousQuestion, setPreviousQuestion] =
    useState<TriviaQuestion | null>(null);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [showPreviousQuestion, setShowPreviousQuestion] = useState(false);
  const [autoFlowMode, setAutoFlowMode] = useState<boolean>(true);
  const [lastQuestionAnswered, setLastQuestionAnswered] =
    useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [timerRunning, setTimerRunning] = useState<boolean>(true);
  const [finalTimerValue, setFinalTimerValue] = useState(0);

  const allQuestions: TriviaQuestion[] = triviaQuestions;
  const currentQuestion = allQuestions[currentQuestionIndex];

  // Timer increment logic
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timerRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerRunning]);

  useEffect(() => {
    // Pause timer when an option is selected
    if (selectedOption !== null) {
      setTimerRunning(false);
    } else {
      setTimerRunning(true);
    }
  }, [selectedOption]);

  useEffect(() => {
    // Update previous question when current question changes
    if (currentQuestionIndex > 0) {
      setPreviousQuestion(allQuestions[currentQuestionIndex - 1]);
    } else {
      setPreviousQuestion(null);
    }
  }, [currentQuestionIndex, allQuestions]);

  useEffect(() => {
    // Initialize userAnswers array with empty strings for each question
    setUserAnswers(Array(triviaQuestions.length).fill(""));
  }, [triviaQuestions.length]);

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    setUserAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentQuestionIndex] = option;
      return updatedAnswers;
    });

    // Calculate score and move to next question after state update
    setScore((prevScore) => {
      const updatedScore =
        option === convertSpecialChars([currentQuestion.correct_answer])[0]
          ? prevScore + 1
          : prevScore;
      // Append score progress for endgame statistics
      setScoreProgress((prevScoreProgress) => [
        ...prevScoreProgress,
        updatedScore,
      ]);
      return updatedScore;
    });

    if (autoFlowMode) {
      proceedToNextQuestion(option);
    } else {
      if (option !== convertSpecialChars([currentQuestion.correct_answer])[0]) {
        const correctAnswerValue = convertSpecialChars([
          currentQuestion.correct_answer,
        ])[0];
        const correctAnswerButton = document.querySelector(
          `button[data-answer="${CSS.escape(correctAnswerValue)}"]`
        );
        if (correctAnswerButton) {
          correctAnswerButton.classList.add("correct-answer");
        }
      }

      // Check if this is the last question
      if (currentQuestionIndex + 1 === allQuestions.length) {
        // Set flag to indicate last question answered
        setLastQuestionAnswered(true);
        setFinalScore(
          score + (option === currentQuestion.correct_answer ? 1 : 0)
        );
      }
    }
  };

  const proceedToNextQuestion = (option: string) => {
    // Highlight correct answer before moving to the next question
    const correctAnswerValue = convertSpecialChars([
      currentQuestion.correct_answer,
    ])[0];
    const correctAnswerButton = document.querySelector(
      `button[data-answer="${CSS.escape(correctAnswerValue)}"]`
    );
    if (correctAnswerButton) {
      correctAnswerButton.classList.add("correct-answer");
    }

    setTimeout(() => {
      setSelectedOption(null);
      if (currentQuestionIndex + 1 < allQuestions.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setTimerRunning(false);
        setFinalTimerValue(timer);
        setQuizEnded(true);
        setFinalScore(
          score + (option === currentQuestion.correct_answer ? 1 : 0)
        );
      }

      const allButtons = document.querySelectorAll("button[data-answer]");
      allButtons.forEach((button) => {
        button.removeAttribute("class");
      });
    }, 3000);
  };

  const handleNextButtonClick = () => {
    setSelectedOption(null);
    if (currentQuestionIndex + 1 < allQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }

    // Clear classes from all buttons with data-answer attribute
    const allButtons = document.querySelectorAll("button[data-answer]");
    allButtons.forEach((button) => {
      button.removeAttribute("class");
    });
  };

  const mergedAnswers: string[] = convertSpecialChars(
    currentQuestion.incorrect_answers
      .concat(currentQuestion.correct_answer)
      .sort()
  );

  const quizBreakdown: QuizBreakdown[] = allQuestions.map(
    (question, index) => ({
      question: convertSpecialChars([question.question])[0],
      correctAnswer: convertSpecialChars([question.correct_answer])[0],
      userAnswer: userAnswers[index],
    })
  );

  function handlePlayAgain() {
    setScoreProgress([0]);
    setLoading(true);
    setShowPreviousQuestion(false);
    setLastQuestionAnswered(false);
    setAutoFlowMode(true);
    setTimer(0);
    setFinalTimerValue(0);
    handleFetchDataClick()
      .then(() => {
        // Reset state after data is fetched
        setQuizEnded(false);
        setCurrentQuestionIndex(0);
        setSelectedOption(null);
        setScore(0);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        setError(true);
      });
  }

  function handleBackToSetup() {
    setScoreProgress([0]);
    setBackToSetup(true);
    setTimer(0);
  }

  if (backToSetup) {
    return <SelectedCategory sortedCategories={sortedCategories} />;
  }

  if (error) {
    return <ServerError />;
  }

  const toggleMode = () => {
    setAutoFlowMode(!autoFlowMode);
  };

  const handleFinishButtonClick = () => {
    setQuizEnded(true);
    setFinalTimerValue(timer);
  };

  return (
    <>
      <Helmet>
        <title>
          {`Playing Quiz For ${categoryInfo.categoryName} - Trivial`}
        </title>
        <meta property="og:site_name" content="Trivial" />
        <meta
          name="description"
          content="Trivial quiz game in progress with the selected setup."
        />
        <meta
          name="keywords"
          content="answer trivia questions, trivia quiz, test your knowledge, trivia game, quiz game, challenge friends, compete in trivia, play trivia, trivia categories"
        />
      </Helmet>
      {loading && <Loader />}
      {quizEnded ? (
        <>
          <TriviaCompletion
            categoryInfo={categoryInfo}
            handlePlayAgain={handlePlayAgain}
            handleBackToSetup={handleBackToSetup}
            finalScore={finalScore}
            numberOfQuestions={allQuestions.length}
            scoreProgress={scoreProgress}
            difficulty={difficulty}
            quizBreakdown={quizBreakdown}
            formattedTimer={formatTime(finalTimerValue)}
          />
        </>
      ) : (
        <>
          <h1 className="categories-header">{categoryInfo.categoryName}</h1>

          <div className="quiz-stats">
            <div className="quiz-question-count">
              <FontAwesomeIcon
                className="quiz-question-icon"
                icon={faClipboardQuestion}
                size="1x"
              />
              Question:{" "}
              <span className="current-question">
                {currentQuestionIndex + 1}
              </span>{" "}
              / {allQuestions.length}
            </div>
            <div className="quiz-score-count">
              <FontAwesomeIcon
                className="quiz-score-icon"
                icon={faClipboardCheck}
                size="1x"
              />
              Score: <span className="current-score">{score}</span> /{" "}
              {allQuestions.length}
            </div>
          </div>

          <div className="difficulty-display">
            Selected difficulty:{" "}
            <span className="difficulty-display-span">{difficulty}</span>
          </div>

          <div className="elapsed-time-container">
            <div className="elapsed-time">{formatTime(timer)}</div>
            <FontAwesomeIcon
              icon={faHourglassStart}
              size="2x"
              shake={selectedOption == null}
            />
          </div>

          <Separator size="small" />

          <div className="flow-toggle-container">
            <button
              className="flow-toggle-button"
              onClick={toggleMode}
              disabled={
                currentQuestionIndex === allQuestions.length - 1 ||
                selectedOption !== null
              }
            >
              {autoFlowMode
                ? "Switch to Manual-Flow mode"
                : "Switch to Auto-Flow mode"}
              {autoFlowMode ? (
                <FontAwesomeIcon icon={faUserCheck} size="1x" />
              ) : (
                <FontAwesomeIcon icon={faRobot} size="1x" />
              )}
            </button>
          </div>

          <div className="quiz-wrapper">
            <div className="quiz-question">
              {convertSpecialChars([currentQuestion.question])}
            </div>
            <div className="quiz-options">
              {mergedAnswers.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleOptionSelect(option)}
                  disabled={selectedOption !== null || quizEnded}
                  data-answer={option}
                  className={
                    selectedOption === option
                      ? option ===
                        convertSpecialChars([currentQuestion.correct_answer])[0]
                        ? "correct-answer"
                        : "wrong-answer"
                      : undefined
                  }
                >
                  {option}
                </button>
              ))}
            </div>

            <div className="next-finish-container">
              {!autoFlowMode && !quizEnded && !lastQuestionAnswered && (
                <button
                  className="next-question-button"
                  onClick={handleNextButtonClick}
                  disabled={selectedOption === null}
                >
                  Next question
                  <FontAwesomeIcon
                    icon={faForwardStep}
                    size="1x"
                    beat={selectedOption !== null}
                  />
                </button>
              )}
              {!autoFlowMode && !quizEnded && lastQuestionAnswered && (
                <button
                  className="finish-quiz-button"
                  onClick={handleFinishButtonClick}
                >
                  Finish quiz
                  <FontAwesomeIcon
                    className="fa-flip"
                    icon={faFlagCheckered}
                    size="1x"
                  />
                </button>
              )}
            </div>

            {currentQuestionIndex > 0 && (
              <div className="show-hide-container">
                <button
                  onClick={() => setShowPreviousQuestion(!showPreviousQuestion)}
                >
                  {showPreviousQuestion ? "Hide" : "Show"} previous question
                  breakdown
                  {showPreviousQuestion ? (
                    <FontAwesomeIcon icon={faAngleUp} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faAngleDown} size="1x" />
                  )}
                </button>

                {showPreviousQuestion && previousQuestion && (
                  <div className="previous-question">
                    <div className="previous-question-text">
                      {convertSpecialChars([previousQuestion.question])}
                    </div>
                    <div className="previous-question-answer">
                      Correct answer: {previousQuestion.correct_answer}
                    </div>
                    <div
                      className={`previous-user-answer ${
                        userAnswers[currentQuestionIndex - 1] ===
                        previousQuestion.correct_answer
                          ? "correct"
                          : "incorrect"
                      }`}
                    >
                      Your answer: {userAnswers[currentQuestionIndex - 1]}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default TriviaQuestions;
