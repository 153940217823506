import { useState } from "react";
import { useNavigate } from "react-router-dom";
import trivialLogo from "../../assets/trivial-logo-main-color.svg";
import whiteTrivialLogo from "../../assets/trivial-logo-white.svg";
import Separator from "../separator/Separator";
import "./Header.css";

function Header() {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleHomepageRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <div className="header">
        <a href="/about">ABOUT</a>
        <img
          className="main-logo"
          src={isHovered ? whiteTrivialLogo : trivialLogo}
          alt="Trivial logo with colored fill"
          onClick={handleHomepageRedirect}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
        <a href="/disclaimer">DISCLAIMER</a>
      </div>
      <Separator size="large" />
    </>
  );
}

export default Header;
